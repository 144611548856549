import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import PageContent from '../components/Pages/PageContent'
import { getPage } from '../api/page'
import { navigate } from 'gatsby'

const Page = ({ location }) => {
    const [documents, setDocuments] = useState(null)
    const [page, setPage] = useState(null)
    const [services, setServices] = useState(null)

    const params = new URLSearchParams(location.search)
    const slug = params.get('slug')

    useEffect(() => {
        (async () => {
            try {
                setDocuments(null)
                setPage(null)
                setServices(null)
                const data = await getPage(slug)
                setDocuments(data.documents.length > 0 ? data.documents : null)
                setPage(data)
                setServices(data.services.length > 0 ? data.services : null)
            } catch (error) {
                setDocuments(null)
                setPage(null)
                setServices(null)
                navigate('/404')
            }
        })()
    }, [slug])

    if(!page) return null

    return (
        <Layout title={page.title}>
            <PageBanner
                pageTitle={page.title}
                homePageText='Inicio'
                homePageUrl='/'
                activePageText={page.title}
            />
            <PageContent
                documents={documents}
                page={page}
                services={services}
            />
        </Layout>
    )
}

export default Page
