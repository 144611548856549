import React from 'react'
import PageSidebar from './PageSidebar'
import Template1 from './Template1'
import Template2 from './Template2'
import Template3 from './Template3'

const PageContent = ({ documents, page, services }) => {

    const getTemplate = () => {
        switch (page.template) {
            case 'template_1': return <Template1 page={page} />
            case 'template_2': return <Template2 page={page} />
            case 'template_3': return <Template3 page={page} />

            default: return null
        }
    }

    return (
        <section className='page-area ptb-50'>
            <div className='container'>
                <div className='row'>
                    <div className={documents ? 'col-lg-8 col-md-12 mb-4' : 'col-12 mb-4'}>
                        {getTemplate()}
                    </div>
                    {documents || services ?
                        <div className='col-lg-4 col-md-12'>
                            <PageSidebar
                                documents={documents}
                                services={services}
                            />
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </section>
    )
}

export default PageContent