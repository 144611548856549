import api from './credentials'
export async function getPage(slug) {
    const data = await  api.get(`${process.env.API_URL}/page?slug=${slug}`)
    .then(res => {
        return res.data
    })
    .catch(err => {
        console.log(err);
        return []
    })
    return data
}