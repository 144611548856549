import React from "react";
import ServiceCard from "./ServiceCard";
import DocumentCard from "./DocumentCard";

const PageSidebar = ({ documents, services }) => {
  return (
    <div className="page-sidebar">
      {services && services.length > 0 ? (
        <div className="download-file">
          <div className="widget widget_services">
            <h3 className="widget-title">Servicios</h3>
            {services.map((service) => {
              return (
                <ServiceCard key={`service-${service.id}`} service={service} />
              );
            })}
          </div>
        </div>
      ) : null}
      {documents && documents.length > 0 ? (
        <div className="download-file">
          <div className="widget widget_documents">
            <h3 className="widget-title">Documentos</h3>
            <ul>
              {documents.map((document) => {
                return document.media.map((media,index) => {
                  return (
                    <DocumentCard
                      key={`document-${index}`}
                      media={media}
                    />
                  );
                });
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PageSidebar;
