import React from 'react'

const Template3 = ({ page }) => {
    const { media, text1 } = page

    return (
        <div className='row'>
            {media.length > 0 && media[0] ?
                <div className='col-12 col-md-5 mb-4'>
                    <img src={media[0].original_url} alt={media[0].name} className='p-3 img-fluid mx-auto d-block' />

                </div>
                :
                null
            }
            <div className={media.length > 0 && media[0] ? 'col-12 col-md-7 mt-md-3' : 'col-12 mt-md-3'}>
                {text1 && text1.split('\n').map((line,index) => (
                    <p key={'text1'+index}>
                        <div dangerouslySetInnerHTML={{ __html: line }} />
                    </p>
                ))}
            </div>
        </div>
    )
}

export default Template3